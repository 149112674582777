<template>
    <b-container fluid>
      <!-- User Interface controls -->
      <b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4> Leads List</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Leads Update</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
	<br>
      <b-card>
      <b-row>
        <b-col lg="2">
          <b-form-group
            label="Sort : "
            label-for="sort-by-select"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            class="mb-2"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-50"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
  
              <!-- <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-10"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select> -->
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="9" class="text-right">
          <b-form-group
            v-model="sortDirection"
            description="Leave all unchecked to filter on all data"
            label-cols-sm="4"
            label-align-sm="center"
            description-align-sm="center"
            class="mb-1"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
            &nbsp; Filter On &nbsp; : &nbsp; <b-form-checkbox value="name">Name</b-form-checkbox><!-- 
              <b-form-checkbox value="total_book">Place</b-form-checkbox> -->
              <b-form-checkbox value="total_invoice">Mobile </b-form-checkbox>
              <b-form-checkbox value="total_invoiced">  Pincode </b-form-checkbox>
             <!--  <b-form-checkbox value="fav">Important</b-form-checkbox> -->
            </b-form-checkbox-group>
          </b-form-group>
          
        </b-col>
        <b-col lg="1" class="text-right">
          <a href="https://apitest.venuebook.in/admin/export_leads" download class="btn btn-success float-right btn-sm" >Export Excel</a> 
        </b-col>
        <b-col lg="3" class="my-1">
          
        <!--  <b-button v-b-modal.modal-1 class="btn btn-info float-right btn-sm">Upload Vendor Excel</b-button> -->
           

        <b-modal id="modal-edit" title="New Vendor Create" 
        :ok-disabled="vendor_disabled"
        centered header-bg-variant="primary" header-text-variant="light" @ok="Vendor_create" ok-title="Save">
        <b-overlay :show="vendor_disabled" rounded="sm" opacity="0.05">
                <b-form ref="leads_form" >
    
      

    <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Hall/Vendor Name:</label>
        <b-form-input
        id="input-live"
        v-model="$v.vendor.name.$model"
        :state="validatevendorState('name')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Hall/Vendor name"
        trim
        >
        </b-form-input>
    </b-form-group>
    <b-form-group id="input-group-2"  label-for="input-2">
      <label for="input-live">Mobile/Landline number:</label>

      <b-input-group >
    <b-input-group-prepend is-text>
      <select style="border:none"  v-model="selecttype">
  <option value="+91">Mobile</option>
  <option value=" ">Landline</option>
</select>
    </b-input-group-prepend>
    <b-input-group-prepend is-text>
      {{ selecttype }}
    </b-input-group-prepend>
    <b-form-input
        id="input-live"
        v-model="$v.vendor.mob.$model"
        :state="validatevendorState('mob')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Mobile number"
        trim
        v-if="selecttype=='+91'"
        >
        </b-form-input>
        <span v-if="selecttype=='+91'">
        <b-form-invalid-feedback 
          id="pehrs-1-live-feedback"
          v-if="$v.vendor.mob.$error"
          >
            <div v-if="!$v.vendor.mob.required">
              Please enter Phone Number
            </div>
            <div
              v-if="
              !$v.vendor.mob.minLength ||
              !$v.vendor.mob.numeric ||
              !$v.vendor.mob.maxLength
              "
              >
                Please enter valid Contact
              </div>
          </b-form-invalid-feedback>
        </span>
          <b-form-input v-else
        id="input-live"
        v-model="$v.vendor.landmob.$model"
        :state="validatevendorState('landmob')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Landline number"
        trim
        >
        </b-form-input>
        <span  v-if="selecttype!='+91'">
        <b-form-invalid-feedback 
          id="pehrs-1-live-feedback"
          v-if="$v.vendor.landmob.$error"
          >
            <div v-if="!$v.vendor.landmob.required">
              Please enter Phone Number
            </div>
            <div
              v-if="
              !$v.vendor.landmob.minLength ||
              !$v.vendor.landmob.numeric ||
              !$v.vendor.landmob.maxLength
              "
              >
                Please enter valid Contact
              </div>
          </b-form-invalid-feedback>
        </span>
  </b-input-group>

      
        
       
    </b-form-group>
    <!-- <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Mobile number:</label>
      


        <b-input-group prepend="+91" class="mb-2">
        <b-form-input
        id="input-live"
        v-model="$v.vendor.mob.$model"
        :state="validatevendorState('mob')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Mobile/Landline number"
        trim
        >
        </b-form-input>
        <b-form-invalid-feedback
          id="pehrs-1-live-feedback"
          v-if="$v.vendor.mob.$error"
          >
            <div v-if="!$v.vendor.mob.required">
              Please enter Phone Number
            </div>
            <div
              v-if="
              !$v.vendor.mob.minLength ||
              !$v.vendor.mob.numeric ||
              !$v.vendor.mob.maxLength
              "
              >
                Please enter valid Contact
              </div>
          </b-form-invalid-feedback>
          </b-input-group>
    </b-form-group> -->

    <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Location:</label>
       <b-form-textarea
            id="textarea"
            v-model="$v.vendor.location.$model"
        :state="validatevendorState('location')"
          
            placeholder="Enter Location..."
            rows="3"
            max-rows="6"
        >
        </b-form-textarea>
    </b-form-group>

    
<b-row>
  <b-col md="6">
        <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Place:</label>
        <b-form-input
        id="input-live"
        v-model="$v.vendor.place.$model"
        :state="validatevendorState('place')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter Place"
        trim
        >
        </b-form-input>
    </b-form-group>
    </b-col>
    <b-col md="6">
        <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Pincode:</label>
        <b-form-input
        id="input-live"
        v-model="$v.vendor.pincode.$model"
        :state="validatevendorState('pincode')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter pincode"
        trim
        >
        </b-form-input>
    </b-form-group>
    </b-col>
    <b-col md="12">
    <b-row>
      <b-col md="10">
        <b-form-group id="input-group-2"  label-for="input-2">
        <label for="input-live">Lead Source:</label>
        <b-form-select
        id="input-live"
        v-model="$v.vendor.source.$model"
        aria-describedby="input-live-help input-live-feedback"
        :options="lead_source"
        :state="validatevendorState('source')"
        v-if="lead_sorce"
        >
        </b-form-select>

        <b-form-input
        id="input-live"
        v-model="$v.vendor.source.$model"
        :state="validatevendorState('source')"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Enter New Suvery"
        trim
        v-else
        >
        
        </b-form-input>

    </b-form-group>
      </b-col>
      <b-col md="2">
        <br>
        <b-button variant="primary" @click="add_new_source" class="mt-2"> {{lead_sorce ? 'Add' : "Close"}} </b-button>
      </b-col>
    </b-row>
        
    </b-col>
   
</b-row>


  

    

</b-form>
</b-overlay>
            </b-modal>

            <b-modal id="modal-1" title="BootstrapVue" centered header-bg-variant="primary" header-text-variant="light" 
            @ok="getImportData"
                ok-title="Import"
                @cancel="closeFile">
                <b-form-group label="Excel File" >
                    <b-form-file
                  type="file"
                  name="import_file"
                  :state="validateFileState('import_file')"
                  v-model="$v.importFile.import_file.$model"
                  accept=".csv, .xls, .xlsx"
                ></b-form-file>
                <b-form-invalid-feedback id="import_file-1-live-feedback"
                  ><span v-if="!this.$v.importFile.import_file.required">
                    Please select file to import pincodes.</span
                  ></b-form-invalid-feedback
                >
                </b-form-group>
            </b-modal>


        </b-col>
  
       
 
      
    </b-row>
  <b-row>
        <b-col sm="1" md="1">
          <b-form-group
          
            label-for="per-page-select"
            
            label-align-sm="left"
            label-size="sm"
            class="mb-2"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>

          
  
        </b-col>
        <b-col lg="5" >
            
         
        </b-col>
        <b-col lg="6" >
        
          <b-row>
            <b-col lg="3" >

</b-col>
            <b-col lg="7" >
              <b-form-group
            
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-2"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
  
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
            </b-col>
            <b-col lg="2" >
              <b-button v-b-modal.modal-edit class="btn btn-info float-right btn-sm">Create New</b-button> 
            </b-col>
          </b-row>
          
        </b-col>
  
      </b-row>
      <b-skeleton-table v-if="load_leads"
      sticky-header=true
      small
  :rows="10"
  :columns="9"
  :table-props="{ bordered: true, striped: true }"
></b-skeleton-table>
      <!-- Main table element -->
      <b-table
      v-if="!load_leads"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        :busy.sync="isBusy"
      >
      
      <template #head(index)>
       
        <!-- <b-form-checkbox
      id="checkbox-1"
      v-model="favourites"
      name="checkbox-1"
      @change="favourites_change"
    >
    
    </b-form-checkbox> -->
    
    <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" >
    <template #button-content>
      &#x1f50d;<span class="sr-only">Search</span>

    </template>
    <b-dropdown-item @click="favourites_change(1)">
      <i class="cursor-pointer"  >
        <img :src="require('@/assets/images/hot-icon.svg')" style="width: 12px;">
      </i> Hot ( for a client which is almost going to convert)</b-dropdown-item>
    <b-dropdown-item @click="favourites_change(2)">
      <i class="cursor-pointer"  >
        <img :src="require('@/assets/images/1413121417.svg')" style="width: 12px;">
      </i> Cold (client not convinced or slow to move)</b-dropdown-item>
    <b-dropdown-item @click="favourites_change(3)"><i class=" cursor-pointer">
      <img :src="require('@/assets/images/umbrella-with-rain-drops.svg')" style="width: 21px;margin-left: -4px;">
    </i> Drizzle ( client interested and waiting for approvals) </b-dropdown-item>
    <b-dropdown-item @click="favourites_change(0)"><i class="cursor-pointer">
      <img :src="require('@/assets/images/close.png')" style="width: 12px;">
    </i> Clear all </b-dropdown-item>
  </b-dropdown>
      </template>
        <template #cell(index)="row">

        <!-- <i v-if="row" :id="`popover-1-${row.item.id}`" :class="{ 'mdi mdi-star fs-10 cursor-pointer':true, [row.item.fav==0 ? '' : 'mdi-color' ]:true}" ></i>
        -->
        <i :id="`popover-1-${row.item.id}`" class="cursor-pointer"  v-if="row.item.fav==1"  >
        <img :src="require('@/assets/images/hot-icon.svg')" style="width: 12px;">
        </i>
        <i :id="`popover-1-${row.item.id}`" class="cursor-pointer"  v-if="row.item.fav==2"  >
          <img :src="require('@/assets/images/1413121417.svg')" style="width: 12px;">
        </i>
        <i :id="`popover-1-${row.item.id}`" class="cursor-pointer"  v-if="row.item.fav==3"  >
          <img :src="require('@/assets/images/umbrella-with-rain-drops.svg')" style="width: 21px;margin-left: -4px;">
        </i>
        <i :id="`popover-1-${row.item.id}`" class="cursor-pointer" v-if="row.item.fav==0" >
          <img :src="require('@/assets/images/Exclamation_Point.svg')" style="width: 8px;margin-left: 3px;">
        </i>


        <b-popover :target="`popover-1-${row.item.id}`" triggers="hover" placement="left">
         
   <i class="cursor-pointer" @click="toogle_favrate(row.item.id,'1')"><img :src="require('@/assets/images/hot-icon.svg')" style="width: 12px;margin-left: 3px;"></i><hr>
   <i class="cursor-pointer" @click="toogle_favrate(row.item.id,'2')" > <img :src="require('@/assets/images/1413121417.svg')" style="width: 12px;"></i><hr>
   <i class="cursor-pointer" @click="toogle_favrate(row.item.id,'3')"><img :src="require('@/assets/images/umbrella-with-rain-drops.svg')" style="width: 21px;margin-left: -4px;"></i><hr>
   <i class="cursor-pointer" @click="toogle_favrate(row.item.id,'0')" >  <img :src="require('@/assets/images/Exclamation_Point.svg')" style="width: 8px;margin-left: 3px;"></i>
  </b-popover>
        </template> 
      <template #cell(name)="data">
   <router-link :to="'/Sales/view_sales_details/' + data.item.id" >
      {{ data.item.name }}
            </router-link> 
            
          </template>
          
          <template #cell(total_invoiced)="data">
            <span v-if="data.item.total_invoiced && data.item.country_code!='+91'">
                      {{ data.item.total_invoiced.substring(0, 4) }} {{  data.item.total_invoiced.substring(4, 11) }} 
                    </span>
                    <span v-else>
                      {{ data.item.country_code }} {{  data.item.total_invoiced }} 
                    </span>
            
          </template>
     
  
        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <b-col sm="2" md="2" class="my-1 float-right">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
            @change="handlePageChange"
            prev-text="Prev"
            next-text="Next"
          ></b-pagination>
        </b-col>
    </b-card>
      <!-- Info modal -->
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>
    </b-container>
  </template>
  
  <script>
   import axios from "axios";
   import { validationMixin } from "vuelidate";
import { required,
		minLength,
		helpers,
		maxLength,
		numeric} from "vuelidate/lib/validators";

    const nameCheck = helpers.regex(
    "nameCheck",
    /^[a-z A-Z]+(?:-[a-z A-Z]+)*$/
	);	

    export default {
        mixins: [validationMixin],
      data() {
        return {
        /*   items: [
            { isActive: true, age: 40, name: { first: 'Dickerson', last: 'Macdonald' } },
            { isActive: false, age: 21, name: { first: 'Larsen', last: 'Shaw' } },
            {
              isActive: false,
              age: 9,
              name: { first: 'Mini', last: 'Navarro' },
              _rowVariant: 'success'
            },
            { isActive: false, age: 89, name: { first: 'Geneva', last: 'Wilson' } },
            { isActive: true, age: 38, name: { first: 'Jami', last: 'Carney' } },
            { isActive: false, age: 27, name: { first: 'Essie', last: 'Dunlap' } },
            { isActive: true, age: 40, name: { first: 'Thor', last: 'Macdonald' } },
            {
              isActive: true,
              age: 87,
              name: { first: 'Larsen', last: 'Shaw' },
              _cellVariants: { age: 'danger', isActive: 'warning' }
            },
            { isActive: false, age: 26, name: { first: 'Mitzi', last: 'Navarro' } },
            { isActive: false, age: 22, name: { first: 'Genevieve', last: 'Wilson' } },
            { isActive: true, age: 38, name: { first: 'John', last: 'Carney' } },
            { isActive: false, age: 29, name: { first: 'Dick', last: 'Dunlap' } }
          ], */
          items:[],
          fields: [
          'index',
          { key: 'vendor_id', label: 'Primary Id', sortable: true, class: 'text-left' },
           // { key: 'id', label: '', sortable: true, sortDirection: 'desc' , class: 'text-left' },
            { key: 'name', label: 'Venue name', sortable: true, sortDirection: 'desc' },
          
            { key: 'total_book', label: 'Place ', sortable: true, class: 'text-center' },
            { key: 'total_invoice', label: 'Pincode', sortable: true, class: 'text-center' },
            { key: 'total_invoiced', label: 'Mobile', sortable: true, class: 'text-center' },
            { key: 'LeadStatus', label: 'Lead Status', sortable: true, sortDirection: 'desc' },
           
           
            { key: 'created_by', label: 'Followed by', sortable: true, class: 'text-center' },
            { key: 'LeadCreated', label: 'Lead Updates', sortable: true, sortDirection: 'desc' },
           // { key: 'total_pending', label: 'VB Invoice Pending', sortable: true, class: 'text-center' },
         //   { key: 'actions', label: 'Actions' }
          /*   {
              key: 'isActive',
              label: 'Is Active',
              formatter: (value, key, item) => {
                key,item
                return value ? 'Yes' : 'No'
              },
              sortable: true,
              sortByFormatted: true,
              filterByFormatted: true
            },
            { key: 'actions', label: 'Actions' } */
          ],
          favourites:false,
          vendor_disabled:false,
          totalRows: 1,
          currentPage: 1,
          perPage: 15,
          pageOptions: [10, 15 , 20, 50, { value: 100, text: "All" }],
          sortBy: '',
          sortDesc: false,
          sortDirection: 'asc',
          filter: null,
          filterOn: [],
          lead_sorce:true,
          lead_source: [],
          selecttype:'+91',
          infoModal: {
            id: 'info-modal',
            title: '',
            content: ''
          },
          load_leads:true,
          isBusy:false,
          uploadFieldName:"",
          importFile: {
      import_file: [],
    },
    vendor:
    {
      name:'',
      mob:'',
      landmob:'',
      place:'',
      location:'',
      pincode:'',
      source:'',
      
    },
        }
      },
      validations: {
    importFile: {
      import_file: { required },
    },
    vendor:
        {
          name:{
           // required,
            nameCheck
          },
          mob:{
            required,
            numeric,
            
            minLength: minLength(10),
            maxLength: maxLength(10),
          }, 
          landmob:{
           // required,
            numeric,
            
            minLength: minLength(11),
            maxLength: maxLength(11),
          },
          place:{
            required
          },
          location:{
            required
          },
          source:{
            required
          },
          pincode:{
            required,
            numeric,
					minLength: minLength(6),
					maxLength: maxLength(6),
          },
          
        },
  },
  computed: {
        sortOptions() {
          // Create an options list from our fields
          return this.fields
            .filter(f => f.sortable)
            .map(f => {
              return { text: f.label, value: f.key }
            })
        }
      },
     
      methods: {
        info(item, index, button) {
            item, index, button
         /*  this.infoModal.title = `Row index: ${index}`
          this.infoModal.content = JSON.stringify(item, null, 2)
          this.$root.$emit('bv::show::modal', this.infoModal.id, button) */
          this.$router.push('/Sales/view_sales_details/'+item)
        },
        resetInfoModal() {
          this.infoModal.title = ''
          this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
        validatevendorState(name) {
				const { $dirty, $error } = this.$v.vendor[name];
				return $dirty ? !$error : null;
			},
       /*  async handlePageChange()
        {
//alert(this.currentPage)
        }, */
        async revenue_list()
        {
            this.isBusy = true
             axios.post("/admin/sales_list",
             {
              favourites:this.favourites
             }).then((resp) => {
        this.items = [];
        this.lead_source = [];
        this.totalRows = resp.data.all_vendors.length
        this.isBusy = false;
        this.load_leads = false;
            for(var i=0; i < resp.data.all_vendors.length;i++)
            {
                this.items.push({
                    id:resp.data.all_vendors[i].id,
                    country_code:resp.data.all_vendors[i].country_code,
                    fav:resp.data.all_vendors[i].fav,
                    name:resp.data.all_vendors[i].name,
                    vendor_id:resp.data.all_vendors[i].id,
                    total_book:resp.data.all_vendors[i].place,
                    total_invoice:resp.data.all_vendors[i].pincode,
                    total_invoiced:resp.data.all_vendors[i].mob,
                    created_by:resp.data.all_vendors[i].created_name,
                    LeadStatus:resp.data.all_vendors[i].lead_status,
                    LeadCreated:resp.data.all_vendors[i].lead_created,
                });

            }

            for(var j=0; j < resp.data.all_source.length;j++)
            {
                this.lead_source.push({
                  value:resp.data.all_source[j].id,
                  text:resp.data.all_source[j].name
                })
            }

            
        });
        },
        getImportData(e) {
      e.preventDefault();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.$v.importFile.$touch();
      if (this.$v.importFile.$anyError) {
        return;
      } else {
        let data = new FormData();
        data.append("import_file", this.importFile.import_file);
        axios.post("/admin/Sales_vendor_insert", data, config).then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status == true) {
              this.$root.$refs.app.showToast("success", resp.data.message);
              //this.close();
              this.revenue_list();
              this.$bvModal.hide("modal-1");
            } else {
              this.$root.$refs.app.showToast("danger", resp.data.message);
            }
          }
        });
      }
    },

    closeFile() {
      this.$nextTick(() => {
        this.$bvModal.hide("ImportPincodeModal");
        this.importFile = {
          import_file: [],
        };
        this.$v.$reset();
      });
    },
    validateFileState(import_file) {
      const { $dirty, $error } = this.$v.importFile[import_file];
      return $dirty ? !$error : null;
    },

    Vendor_create(bvModalEvent)
      {
        bvModalEvent.preventDefault();
        this.selecttype=='+91' ? this.vendor.landmob = "00000000000" : this.vendor.mob = "0000000000";
        this.$v.vendor.$touch();
				if (this.$v.vendor.$anyError) {
					return;
					} else {
            this.vendor_disabled=true;
        axios.post("/admin/Vendor_create",
				{
					name:this.vendor.name,
					number:this.selecttype=='+91' ?  this.vendor.mob : this.vendor.landmob,
					place:this.vendor.place,
					location:this.vendor.location,
					pincode:this.vendor.pincode,
					source:this.vendor.source,
					vendor_id : this.vendor_id,
          lead_sorce:this.lead_sorce,
          selecttype:this.selecttype,
				})
				.then((resp) => {
          this.vendor_disabled=false;
					resp;
          this.lead_sorce=true;
          this.vendor.name='';
          this.vendor.mob='';
          this.vendor.place='';
          this.vendor.location='';
          this.vendor.pincode='';
          this.vendor.source='';
          this.vendor_id='';
					
          this.$bvModal.hide("modal-edit");

          if (resp.data.status) {
                        this.$root.$refs.app.showToast("success",'Vendor Created Successfull');
                      //  this.$router.push("/login");
						} else {
                        this.$root.$refs.app.showToast("danger", resp.data.message);
					}
          //message
         // this.$root.$refs.app.showToast("success", "Vendor Created Successfull");
          this.revenue_list();
				});
      }
      },
      toogle_favrate(id,status)
      {
        axios.post("/admin/vendor_favarotes",
				{
					id:id,
          status:status
				})
				.then((resp) => {
          resp;
          this.revenue_list();
        })
      },
      add_new_source()
      {
          this.lead_sorce=!this.lead_sorce;
      },
      favourites_change(status)
      {
        this.favourites=status;
        this.revenue_list();
      },
      exportTable() {
        axios
    .post('admin/export_leads', {
        responseType: "blob"
      })
    .then(response => {
      console.log(new Blob([response.data]));
      const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'abcd.xlsx')
          document.body.appendChild(link)
          link.click()
    })
    },
    
      },
      mounted() {
        // Set the initial number of items
        this.revenue_list();
        
      },
    }
  </script>
  <style>
  .fs-10
  {
    font-size: 15px;
  }
  .mdi-color
  {
    color:goldenrod;
  }
  </style>